<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  }
});
</script>

<style>
.control-head .icon-inner svg * {
    color: #fff;
    stroke: #fff !important;
}
ion-button {
    height: 48px;
}
</style>
